import { Controller } from '@hotwired/stimulus'
import { scrollTrigger } from '../../utils/animation'

export default class extends Controller {
  static targets = ['item']

  connect() {
    const targets = this.hasItemTarget ? this.itemTargets : [this.element]

    targets.forEach(target => {
      target.classList.add('is-inactive')

      scrollTrigger(target, {
        rootMargin: '50px',
        class: 'is-inactive',
        force: false
      })
    })
  }
}
