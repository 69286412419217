const IT = {
  prev: 'Diapositiva precedente',
  next: 'Prossima diapositiva',
  first: 'Vai alla prima diapositiva',
  last: 'Vai all\'ultima diapositiva',
  slideX: 'Vai alla diapositiva %s',
  pageX: 'Vai alla pagina %s',
  play: 'Avvia riproduzione automatica',
  pause: 'Pausa riproduzione automatica',
  carousel: 'Carosello',
  select: 'Seleziona una diapositiva da visualizzare',
};

export { IT }