import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

import { language } from 'scripts/utils/document'
import { DE } from 'scripts/application/locales/splide/de'
import { EN } from 'scripts/application/locales/splide/en'
import { ES } from 'scripts/application/locales/splide/es'
import { FR } from 'scripts/application/locales/splide/fr'
import { IT } from 'scripts/application/locales/splide/it'

export default class extends Controller {
  connect() {
    this.splide = new Splide(this.element, {
      i18n: this.locale,
      pagination: false,
      perPage: 2,
      gap: '5.6rem',
      breakpoints: {
        1199: {
          gap: '4rem',
        },
        1023: {
          perPage: 2,
          gap: '1.2rem',
        },
        767: {
          arrows: false,
          perPage: 1,
          gap: '2.4rem',
        },
      }
    })

    this.splide.on('overflow', (isOverflow) => {
      this.splide.options = {
        drag: isOverflow,
      }
    })

    this.splide.mount()
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      de: DE,
      en: EN,
      es: ES,
      fr: FR,
      it: IT
    }
  }
}
