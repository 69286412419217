import Rails from '@rails/ujs'
import Confirm from 'scripts/application/confirm'

const confirm = (data, callback) => {
  const link = document.createElement('a')
  link.href = data.url
  link.dataset.confirm = true
  link.dataset.confirmUrl = data.confirmUrl
  link.dataset.confirmTitle = data.confirmTitle
  link.dataset.confirmText = data.confirmText
  link.dataset.confirmDenyText = data.confirmDenyText
  link.dataset.confirmAcceptText = data.confirmAcceptText
  document.body.appendChild(link)

  if (!Confirm.allowAction(link)) {
    link.remove()
    Rails.stopEverything(event)
  }
}

export { confirm }
