import { Controller } from '@hotwired/stimulus'
import { toggleBodyLock } from 'scripts/utils/document'

export default class extends Controller {
  connect() {
    this.setScrollState()
  }

  handleToggleNav(event) {
    this.toggleNav()
  }

  onWindowScroll(event) {
    this.setScrollState()
  }

  toggleNav(force) {
    this.element.classList.toggle('is-open', force)
    toggleBodyLock(this.element.classList.contains('is-open'))
  }

  setScrollState() {
    this.element.classList.toggle('is-scrolled', window.scrollY > 60)
  }
}
