import Rails from '@rails/ujs'

const queryString = data => {
  return new URLSearchParams(data).toString()
}

const ajax = async config => {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      ...config,
      success: data => {
        resolve(data)
      },
    })
  })
}

const get = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data: queryString(data), type: 'GET', dataType: dataType })
}

const post = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data, type: 'POST', dataType: dataType })
}

const patch = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data, type: 'PATCH', dataType: dataType })
}

const remove = async (url, data = {}, dataType = 'script') => {
  return await ajax({ url, data, type: 'DELETE', dataType: dataType })
}

export { queryString, ajax, get, post, patch, remove }
