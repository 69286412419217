import { Controller } from '@hotwired/stimulus'
import { language } from 'scripts/utils/document'

export default class extends Controller {
  connect() {
    this.connected = true
    this.refresh()
  }

  disconnect() {
    this.connected = false
  }

  refresh() {
    if (!this.connected) return
    requestAnimationFrame(() => this.display())
  }

  display() {
    const templateParts = [
      this.templatePart(Math.floor(this.remainingTime / (24 * 60 * 60 * 1000)), 'day'),
      this.templatePart(Math.floor((this.remainingTime / (60 * 60 * 1000)) % 24), 'hour'),
      this.templatePart(Math.floor((this.remainingTime / (60 * 1000)) % 60), 'minute'),
      this.templatePart(Math.floor((this.remainingTime / 1000) % 60), 'second')
    ]

    this.element.innerHTML = templateParts.join(' - ')

    if (this.remainingTime >= 1000) {
      this.refresh()
    }
  }

  templatePart(part, type) {
    const rtf = new Intl.RelativeTimeFormat(language, { numeric: 'always' })
    const zero = new Intl.NumberFormat(language).format(0)
    const parts = rtf.formatToParts(part === 0 ? 2 : part, type)

    if (parts && parts.length === 3) {
      parts.shift()
    }

    const [unit, label] = parts
    return `<span class="u-nowrap">${part === 0 ? zero : unit.value} ${label.value}</span>`
  }

  get targetTime() {
    return new Date(this.element.getAttribute('datetime')).getTime()
  }

  get currentTime() {
    return new Date().getTime()
  }

  get remainingTime() {
    return this.targetTime > this.currentTime ? this.targetTime - this.currentTime : 0
  }
}
