const FR = {
  loadingText: 'Chargement...',
  noResultsText: 'Aucun résultat trouvé',
  noChoicesText: 'Aucun choix disponible',
  itemSelectText: '',
  uniqueItemText: 'Seules les valeurs uniques peuvent être ajoutées',
  customAddItemText: 'Seules les valeurs correspondant à des conditions spécifiques peuvent être ajoutées',
  addItemText: (value) => {
    return `Appuyez sur Entrée pour ajouter <b>"${value}"</b>`;
  },
  maxItemText: (maxItemCount) => {
    return `Seulement ${maxItemCount} valeurs peuvent être ajoutées`;
  }
}

export { FR }
