import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['withBoatFields', 'withoutBoatFields', 'withBoatRadio']

  connect() {
    if (this.hasWithBoatRadioTarget) {
      this.toggleBoatFields(this.withBoatRadioTarget.value)
    }
  }

  onSubmit(event) {
    this.wrapperElement.classList.add('is-loading')
  }

  onWithBoatRadioChange(event) {
    this.toggleBoatFields(event.currentTarget.value)
  }

  toggleBoatFields(withBoatValue) {
    withBoatValue = (/true/i).test(withBoatValue)
    this.withBoatFieldsTarget.classList.toggle('u-d-none', !withBoatValue)
    this.withoutBoatFieldsTarget.classList.toggle('u-d-none', !!withBoatValue)
  }

  get wrapperElement() {
    return this.element.closest('[data-controller~="world-escapade--step"]')
  }
}
