const DE = {
  prev: 'Vorherige Folie',
  next: 'Nächste Folie',
  first: 'Gehe zur ersten Folie',
  last: 'Gehe zur letzten Folie',
  slideX: 'Gehe zur Folie %s',
  pageX: 'Gehe zur Seite %s',
  play: 'Automatische Wiedergabe starten',
  pause: 'Automatische Wiedergabe pausieren',
  carousel: 'Karussell',
  select: 'Wähle eine Folie aus, um sie anzuzeigen',
};

export { DE }