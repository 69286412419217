import { Controller } from '@hotwired/stimulus'
import { breakpoint } from '../../../utils/document'

export default class extends Controller {
  static targets = ['checkbox', 'select']

  connect() {
    this.setInputsState()
  }

  onWindowResize() {
    this.setInputsState()
  }

  onBenefitTypeChange(event) {
    event.currentTarget.closest('form').submit()
  }

  setInputsState() {
    const isMobile = window.matchMedia(`(max-width: ${breakpoint('md')}px)`).matches
    this.checkboxTargets.forEach(checkboxTarget => checkboxTarget.disabled = isMobile)

    if (this.hasSelectTarget) {
      this.selectTarget.disabled = !isMobile
    }
  }
}
