import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'list']

  initialize() {
    window.addEventListener('load', event => this.setScrollPosition())
  }

  connect() {
    this.setScrollPosition()
  }

  setScrollPosition() {
    this.itemTargets.forEach(item => {
      if (item.classList.contains('is-active')) {
        const scrollPosition = item.offsetLeft + (item.offsetWidth * 0.5) - (this.listTarget.offsetWidth * 0.5)
        this.listTarget.scrollLeft = scrollPosition
      }
    })
  }
}
