import { Controller } from '@hotwired/stimulus'
import { focusableSelector } from 'scripts/utils/document'

export default class extends Controller {
  static targets = ['form', 'input', 'step']
  static values = {
    step: Number
  }

  connect() {
    this.firstStep = 1
    this.finalStep = 2
    this.currentStep = this.hasStepValue ? this.stepValue : this.firstStep

    this.goToStep()
  }

  handleGoToPreviousStep(event) {
    this.goToPreviousStep()
  }

  handleGoToNextStep(event) {
    this.setInputValue(event.params.input, event.params.value)

    if (event.params.value === 'waiting_for_delivery') {
      this.setInputValue('is_new', true)
      ++this.currentStep
    }

    this.goToNextStep()
  }

  goToPreviousStep() {
    --this.currentStep
    this.goToStep()
    this.focusOnStep()
  }

  goToNextStep() {
    ++this.currentStep
    this.goToStep()
    this.focusOnStep()
  }

  goToStep() {
    if (this.currentStep < 1) {
      this.currentStep = 1
    }

    if (this.currentStep > this.finalStep) {
      this.submitForm()
    } else {
      this.stepTargets.forEach(stepTarget => {
        const isCurrentStep = parseInt(stepTarget.dataset.step) == this.currentStep
        stepTarget.classList.toggle('u-d-none', !isCurrentStep)
      })
    }
  }

  focusOnStep() {
    this.stepTargets.forEach(stepTarget => {
      if (parseInt(stepTarget.dataset.step) == this.currentStep) {
        const firstFocusableItem = stepTarget.querySelector(focusableSelector)
        if (firstFocusableItem) firstFocusableItem.focus()
      }
    })
  }

  setInputValue(id, value) {
    const input = this.inputTargets.find(inputTarget => inputTarget.id === id)

    if (input) {
      input.value = value
    }
  }

  submitForm() {
    this.formTarget.submit()
  }
}
