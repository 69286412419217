import { Controller } from '@hotwired/stimulus'
import { confirm } from 'scripts/utils/confirm'

export default class extends Controller {
  initialize() {
    this.isDirty = false
  }

  connect() {
    this.initListeners()

    document.addEventListener('turbolinks:before-visit', event => {
      if (this.isDirty) {
        event.preventDefault()

        confirm({
          url: event.data.url,
          confirmUrl: this.element.dataset.confirmUrl,
          confirmTitle: this.element.dataset.confirmTitle,
          confirmText: this.element.dataset.confirmText,
          confirmDenyText: this.element.dataset.confirmDenyText,
          confirmAcceptText: this.element.dataset.confirmAcceptText
        })
      }
    })
  }

  initListeners() {
    this.element.querySelectorAll('.c-form-control').forEach((item) => {
      item.addEventListener('change', () => {
        this.isDirty = true
      })
    })
  }
}
