const scrollTrigger = (el, options = {}) => {
  options.class ??= 'is-active'
  options.force ??= true

  const intersection = target => {
    if (options.onIntersection) {
      options.onIntersection(el)
    } else {
      target.classList.toggle(options.class, options.force)
    }
  }

  if (!('IntersectionObserver' in window)) {
    intersection(el)
    return
  }

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        intersection(entry.target)
        observer.unobserve(entry.target)
      }
    })
  }, options)

  observer.observe(el)
}

export { scrollTrigger }
