import { Controller } from '@hotwired/stimulus'
import videojs from 'video.js'
import DE from 'video.js/dist/lang/de.json'
import EN from 'video.js/dist/lang/en.json'
import ES from 'video.js/dist/lang/es.json'
import FR from 'video.js/dist/lang/fr.json'
import IT from 'video.js/dist/lang/it.json'

videojs.addLanguage('de', DE)
videojs.addLanguage('en', EN)
videojs.addLanguage('es', ES)
videojs.addLanguage('fr', FR)
videojs.addLanguage('it', IT)

export default class extends Controller {
  connect() {
    videojs(this.element)
  }
}
