import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  handleToggleVisibility(event) {
    this.element.classList.toggle('is-visible')
    this.inputTarget.type = this.element.classList.contains('is-visible') ? 'text' : 'password'
  }
}
