const removeOTSDK = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')

  if (!otConsentSdk) return
  otConsentSdk.remove()
}

const reloadOTBanner = () => {
  window.OneTrust.Init()

  setTimeout(function() {
    window.OneTrust.LoadBanner()

    const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings')

    for (var i = 0; i < toggleDisplay.length; i++) {
      toggleDisplay[i].onclick = function(event) {
        event.stopImmediatePropagation()
        window.OneTrust.ToggleInfoDisplay()
      }
    }
  }, 1000)
}

const clearOTPolicyDuplicate = () => {
  const policyList = document.getElementById('ot-sdk-cookie-policy')
  const versions = []

  if (!policyList) return

  for (var i = policyList.length - 1; i >= 0; i--) {
    if (versions[policyList[i].firstChild.innerText] === undefined) {
      versions[policyList[i].firstChild.innerText] = 1
    } else {
      policyList[i].remove()
    }
  }
}

// Manage OneTrust reload on page change
document.addEventListener('turbolinks:load', event => {
  if (!window.OneTrust) return

  removeOTSDK()
  reloadOTBanner()
  clearOTPolicyDuplicate()
})
