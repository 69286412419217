import { Controller } from '@hotwired/stimulus'
import { viewportOffset } from '../../../utils/document'

export default class extends Controller {
  static values = {
    ajaxLoaded: Boolean,
    status: String
  }

  connect() {
    if (this.ajaxLoadedValue) {
      window.scrollTo(0, viewportOffset(this.element).top - 120)

      // Send GTM event when completed only on Ajax refresh
      if (this.statusValue === 'completed') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'contact.confirmation',
          'contact_name': 'event',
          'contact_subject': 'event',
          'contact_type': 'form'
        })
      }
    }
  }
}
