const ES = {
  prev: 'Diapositiva anterior',
  next: 'Siguiente diapositiva',
  first: 'Ir a la primera diapositiva',
  last: 'Ir a la última diapositiva',
  slideX: 'Ir a la diapositiva %s',
  pageX: 'Ir a la página %s',
  play: 'Iniciar reproducción automática',
  pause: 'Pausar reproducción automática',
  carousel: 'Carrusel',
  select: 'Selecciona una diapositiva para mostrar',
};

export { ES }