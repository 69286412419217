import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['companyNameInput']

  onTypeRadioChange(event) {
    this.toggleCompanyNameInput(event.currentTarget)
  }

  toggleCompanyNameInput(element) {
    if (element.value == "individual") {
      this.companyNameInputTarget.classList.add('u-d-none')
    } else {
      this.companyNameInputTarget.classList.remove('u-d-none')
    }
  }
}