const FR = {
  prev: 'Slide précédente',
  next: 'Slide suivante',
  first: 'Aller à la première slide',
  last: 'Aller à la dernière slide',
  slideX: 'Aller à la slide %s',
  pageX: 'Aller à la page %s',
  play: 'Commencer la lecture automatique',
  pause: 'Mettre en pause la lecture automatique',
  carousel: 'Carousel',
  select: 'Sélectionner une slide à afficher',
}

export { FR }
