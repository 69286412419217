import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  onClick(event) {
    this.wrapperElement.classList.add('is-loading')
  }

  get wrapperElement() {
    return this.element.closest('[data-controller~="world-escapade--step"]')
  }
}
