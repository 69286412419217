const EN = {
  prev: 'Previous slide',
  next: 'Next slide',
  first: 'Go to first slide',
  last: 'Go to last slide',
  slideX: 'Go to slide %s',
  pageX: '	Go to page %s',
  play: 'Start autoplay',
  pause: 'Pause autoplay',
  carousel: 'Carousel',
  select: 'Select a slide to display',
}

export { EN }