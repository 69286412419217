import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox({
      selector: '[data-controller~="event--lightbox"]'
    })
  }
}
