import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js/public/assets/scripts/choices'

import { language } from 'scripts/utils/document'
import { EN } from 'scripts/application/locales/choices.js/en'
import { FR } from 'scripts/application/locales/choices.js/fr'

export default class extends Controller {
  static targets = ['select']

  connect() {
    this.setup()
  }

  setup() {
    this.choices = new Choices(this.selectTarget, this.options)
  }

  get options() {
    const options = {
      position: 'bottom',
      searchFields: ['label'],
      searchResultLimit: 10000
    }

    return { ...options, ...this.locale }
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      en: EN,
      fr: FR
    }
  }
}
