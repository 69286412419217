import { Controller } from '@hotwired/stimulus'
import { confirm } from 'scripts/utils/confirm'

export default class extends Controller {
  connect() {
    this.connected = true

    if (this.element.dataset.confirmUrl) {
      document.addEventListener('turbolinks:before-visit', this.handleDisplayConfirm.bind(this))
    }
  }

  disconnect() {
    this.connected = false
  }

  handleDisplayConfirm(event) {
    if (this.connected && event.target.activeElement?.dataset?.confirmation !== 'false') {
      event.preventDefault()

      confirm({
        url: event.data.url,
        confirmUrl: this.element.dataset.confirmUrl,
        confirmTitle: this.element.dataset.confirmTitle,
        confirmText: this.element.dataset.confirmText,
        confirmDenyText: this.element.dataset.confirmDenyText,
        confirmAcceptText: this.element.dataset.confirmAcceptText
      })
    }
  }
}
