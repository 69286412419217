import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import { Application } from '@hotwired/stimulus'
import 'scripts/application/onetrust'

import Confirm from 'scripts/application/confirm'

Confirm.init() // Confirm init always before Rails start

Rails.start()
Turbolinks.start()

window.Images = require.context('images', true)

window.Stimulus = Application.start()
