import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import debounce from 'lodash.debounce'
import mapboxgl from '!mapbox-gl'
import { get } from '../../../utils/ajax'
import { average, color, mapboxAccessToken, siteCode } from '../../../utils/document'
import { serialize } from '../../../utils/form'
import { getControllerForElement } from '../../../utils/stimulus'
import excessMarkerImage from '../../../../images/excess/icons/map-marker.png'
import lagoonMarkerImage from '../../../../images/lagoon/icons/map-marker.png'

export default class extends Controller {
  static targets = ['card', 'distributorInput', 'geocodingResults', 'geolocationButton', 'list', 'map', 'searchInput', 'searchForm', 'submitButton']
  static values = {
    centerCountry: String,
    totalCount: Number
  }

  initialize() {
    mapboxgl.accessToken = mapboxAccessToken
  }

  connect() {
    this.debouncedGetGeocodingLocations = debounce(this.getGeocodingLocations, 600)
    this.mapDefaultCenterCoords = [1.7191036, 46.71109]
    this.mapDefaultZoom = 4
    this.geocodingTypes = ['region', 'postcode', 'district', 'place', 'locality']

    if (!navigator.geolocation) {
      this.geolocationButtonTarget.remove()
    }

    this.setDistributors()
    this.initMap()

    this.mapbox.on('load', event => {
      this.initMapMarkers()
      this.setMapMarkers()

      if (this.hasCenterCountryValue) {
        this.getUserCenterCoords()
      } else {
        this.centerMap()
      }
    })
  }

  onGeolocationButtonClick(event) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        this.reverseGeocoding(latitude, longitude)
        this.setGeocodingPosition(latitude, longitude)
      },
      (error) => {
        console.log(error)
        const latitude = 46.71109, longitude = 1.7191036
        this.reverseGeocoding(latitude, longitude)
        this.setGeocodingPosition(latitude, longitude)
      }
    )
  }

  onGeocodingInputChange(event) {
    this.debouncedGetGeocodingLocations(event.currentTarget.value)
  }

  onGeocodingLocationClick({ params: { location } }) {
    const geocodingInput = this.searchInputTargets.find(target => target.name === 'geocoding')
    if (!geocodingInput) return
    geocodingInput.value = location.label
    this.setGeocodingPosition(location.lat, location.lng)
    this.geocodingResultsTarget.classList.remove('is-open')
  }

  onGeocodingResultsMouseenter(event) {
    this.geocodingResultsTarget.classList.add('is-open')
  }

  onGeocodingResultsMouseleave(event) {
    this.geocodingResultsTarget.classList.remove('is-open')
  }

  onSearchInputChange(event) {
    this.searchInputTargets.forEach(searchInput => {
      if (searchInput !== event.currentTarget) {
        this.clearSearchInput(searchInput)
      }
    })

    this.geocodingResultsTarget.innerHTML = ''

    this.submitSearchForm()
  }

  onSearchFormSubmit(event) {
    event.preventDefault()
    this.updateList()
  }

  handleDistributorSelection(event) {
    const id = event.params.id

    if (!id) return
    this.selectDistributor(id)
  }

  submitSearchForm() {
    Rails.fire(this.searchFormTarget, 'submit')
  }

  clearSearchInput(searchInput) {
    if (searchInput.classList.contains('choices__input')) {
      const advancedSelectElement = searchInput.parentElement.closest(`[data-controller*="inputs--advanced-select"]`)
      const advancedSelectController = getControllerForElement(this, advancedSelectElement, 'inputs--advanced-select')
      advancedSelectController.choices.setChoiceByValue('')
    } else {
      searchInput.value = ''
    }
  }

  setSubmitButtonState() {
    this.submitButtonTarget.disabled = !this.distributorInputTarget.value
  }

  selectDistributor(id) {
    this.setDistributorInputValue(id)

    this.cardTargets.forEach(cardTarget => {
      cardTarget.classList.toggle('is-selected', parseInt(cardTarget.dataset.id) === id)
    })
  }

  setDistributorInputValue(value) {
    if (this.hasDistributorInputTarget) {
      this.distributorInputTarget.value = value
      this.setSubmitButtonState()
    }
  }

  reverseGeocoding(lat, lng) {
    const geocodingInput = this.searchInputTargets.find(target => target.name === 'geocoding')

    if (!geocodingInput) return

    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxAccessToken}&limit=1&types=${this.geocodingTypes.join(',')}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        geocodingInput.value = data.features[0].place_name
      })
  }

  getGeocodingLocations(value) {
    this.geocodingResultsTarget.innerHTML = ''

    if (value) {
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxAccessToken}&limit=10&types=${this.geocodingTypes.join(',')}`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          this.geocodingResultsTarget.innerHTML = data.features.map(feature => {
            return `
              <li>
                <button type="button" data-action="click->distributor--list#onGeocodingLocationClick" data-distributor--list-location-param='{"lat":${feature.geometry.coordinates[1]}, "lng":${feature.geometry.coordinates[0]}, "label":"${feature.place_name}"}'>${feature.place_name}</button>
              </li>
            `
          }).join('')
        })
    }
  }

  setGeocodingPosition(lat, lng) {
    const latInput = this.searchInputTargets.find(target => target.name === 'latitude')
    const lngInput = this.searchInputTargets.find(target => target.name === 'longitude')

    if (!lat || !lng) return
    if (!latInput || !lngInput) return

    latInput.value = lat
    lngInput.value = lng

    this.searchInputTargets.forEach(searchInput => {
      if (!['latitude', 'longitude', 'geocoding'].some(key => searchInput.name === key)) {
        this.clearSearchInput(searchInput)
      }
    })

    this.submitSearchForm()
  }

  async updateList() {
    this.listTarget.innerHTML = ''
    this.listTarget.classList.add('is-loading')
    this.setDistributorInputValue('')

    const result = await get(this.searchFormTarget.action, serialize(this.searchFormTarget), 'html')
    const resultList = result.getElementById('distributor-list')
    this.listTarget.classList.remove('is-loading')

    if (!resultList) return

    this.listTarget.innerHTML = resultList.innerHTML
    this.setDistributors()
    this.setMapMarkers()
    this.centerMap()
  }

  initMap() {
    this.mapbox = new mapboxgl.Map({
      container: 'distributor-map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: this.mapDefaultCenterCoords,
      zoom: this.mapDefaultZoom,
      minZoom: 3,
      maxZoom: 17
    })

    this.mapbox.addControl(new mapboxgl.NavigationControl({ showCompass: false }))

    this.mapbox.on('moveend', () => {
      this.filterDistributorCards()
    })
  }

  initMapMarkers() {
    this.mapbox.addSource('distributors', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50
    })

    this.mapbox.addLayer({
      id: 'cluster',
      type: 'circle',
      source: 'distributors',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          color('primary'),
          100,
          color('primary'),
          750,
          color('primary')
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    })

    this.mapbox.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'distributors',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Arial Unicode MS Bold'],
        'text-size': 14
      },
      paint: {
        'text-color': '#ffffff'
      }
    })

    this.mapbox.loadImage(this.markerImage, (error, image) => {
      if (error) throw error

      this.mapbox.addImage('pin', image)

      this.mapbox.addLayer({
        id: 'pin',
        type: 'symbol',
        source: 'distributors',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'pin',
          'icon-size': 0.75
        }
      })
    })

    this.mapbox.on('click', 'cluster', event => {
      const firstFeature = this.mapbox.queryRenderedFeatures(event.point, { layers: ['cluster'] })[0]
      const clusterId = firstFeature.properties.cluster_id

      this.mapbox.getSource('distributors').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return

        this.mapbox.easeTo({
          center: firstFeature.geometry.coordinates,
          zoom: zoom
        })
      })
    })

    this.mapbox.on('mouseenter', 'cluster', event => {
      this.mapbox.getCanvas().style.cursor = 'pointer'
    })

    this.mapbox.on('mouseleave', 'cluster', event => {
      this.mapbox.getCanvas().style.cursor = ''
    })


    this.mapbox.on('click', 'pin', event => {
      const firstFeature = event.features[0]
      const id = firstFeature.properties.id

      this.selectDistributor(id)
    })

    this.mapbox.on('mouseenter', 'pin', event => {
      this.mapbox.getCanvas().style.cursor = 'pointer'
    })

    this.mapbox.on('mouseleave', 'pin', event => {
      this.mapbox.getCanvas().style.cursor = ''
    })
  }

  setDistributors() {
    this.distributors = this.cardTargets.map(cardTarget => {
      return {
        id: parseInt(cardTarget.dataset.id),
        coords: {
          lng: cardTarget.dataset.lng ? parseFloat(cardTarget.dataset.lng) : undefined,
          lat: cardTarget.dataset.lng ? parseFloat(cardTarget.dataset.lat) : undefined
        }
      }
    })
  }

  filterDistributorCards() {
    const visibleDistributorsIds = this.distributorsWithCoords
      .filter(distributor => this.mapbox.getBounds().contains([distributor.coords.lng, distributor.coords.lat]))
      .map(distributor => distributor.id)

    this.cardTargets.forEach(card => {
      card.classList.toggle('is-inbound', visibleDistributorsIds.length < 0 || visibleDistributorsIds.includes(Number(card.dataset.id)))
    })
  }

  setMapMarkers() {
    const features = this.distributorsWithCoords.map(distributor => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [distributor.coords.lng, distributor.coords.lat]
        },
        properties: {
          id: distributor.id
        }
      }
    })

    this.mapbox.getSource('distributors').setData({
      features: features
    })
  }

  centerMap() {
    const distributorsLngs = this.distributorsWithCoords.map(distributor => distributor.coords.lng)
    const distributorsLats = this.distributorsWithCoords.map(distributor => distributor.coords.lat)
    const zoom = this.distributorsWithCoords.length === 1 ? 10 : this.mapDefaultZoom
    let centerCoords = distributorsLngs.length > 0 && distributorsLats.length > 0 ? [average(distributorsLngs), average(distributorsLats)] : this.mapDefaultCenterCoords

    if (this.userCenterCoords && this.hasTotalCountValue && this.distributors.length === this.totalCountValue) {
      centerCoords = this.userCenterCoords
    }

    this.mapbox.flyTo({
      center: centerCoords,
      zoom: zoom
    })
  }

  getUserCenterCoords() {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.centerCountryValue}.json?access_token=${mapboxAccessToken}&limit=1&types=country`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.userCenterCoords = data.features[0]?.center
        this.centerMap()
      })
      .catch(error => {
        this.centerMap()
      })
  }

  get distributorsWithCoords() {
    return this.distributors.filter(distributor => distributor.coords.lng && distributor.coords.lat)
  }

  get markerImage() {
    switch(siteCode) {
      case 'excess': return excessMarkerImage
      case 'lagoon': return lagoonMarkerImage
    }
  }
}
