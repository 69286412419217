import { Controller } from '@hotwired/stimulus'
import { viewportOffset } from '../../utils/document'

export default class extends Controller {
  connect() {
    this.fixScrollPersistance()

    if (window.location.hash) {
      const target = document.querySelector(window.location.hash.trim())

      if (target) {
        window.scrollTo(0, viewportOffset(target).top - 120)
      }
    }
  }

  fixScrollPersistance() {
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
  }
}
