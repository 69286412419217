import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox({
      skin: 'construction',
      selector: '[data-controller~="boat--construction-lightbox"]',
      moreLength: 0
    })
  }
}
