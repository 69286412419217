import { Controller } from '@hotwired/stimulus'
import mapboxgl from '!mapbox-gl'
import { mapboxAccessToken, siteCode } from '../../utils/document'
import excessMarkerImage from '../../../images/excess/icons/map-marker.png'
import lagoonMarkerImage from '../../../images/lagoon/icons/map-marker.png'

export default class extends Controller {
  static values = {
    coords: Object
  }

  initialize() {
    mapboxgl.accessToken = mapboxAccessToken
  }

  connect() {
    if (!this.hasCoordsValue || !this.coordsValue.lng || !this.coordsValue.lat) return

    this.coords = [this.coordsValue.lng, this.coordsValue.lat]

    this.initMap()

    this.mapbox.on('load', event => {
      this.initMarker()
    })
  }

  initMap() {
    this.mapbox = new mapboxgl.Map({
      container: 'address-map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: this.coords,
      zoom: 13,
      minZoom: 3,
      maxZoom: 17,
      dragPan: false
    })

    this.mapbox.addControl(new mapboxgl.NavigationControl({ showCompass: false }))

    this.mapbox.on('moveend', () => {
      this.mapbox.flyTo({
        center: this.coords
      })
    })
  }

  initMarker(){
    this.mapbox.loadImage(this.markerImage, (error, image) => {
      if (error) throw error

      this.mapbox.addImage('pin', image)

      this.mapbox.addSource('marker', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: this.coords
              }
            }
          ]
        }
      })

      this.mapbox.addLayer({
        id: 'pin',
        type: 'symbol',
        source: 'marker',
        layout: {
          'icon-image': 'pin',
          'icon-size': 0.75
        }
      })
    })
  }

  get markerImage() {
    switch(siteCode) {
      case 'excess': return excessMarkerImage
      case 'lagoon': return lagoonMarkerImage
    }
  }
}
